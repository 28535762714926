import React from 'react';

class TermsOfUse extends React.Component {
  render() {
    return (
      <div className="Legal">
        <div className="LegalContainer">
          <div className="LegalTitle">
          Terms of Use
          </div>
          <div className="LegalSubTitle">
          Last Updated: August 28, 2022
          </div>
          <div className="LegalSection">
          These terms and conditions (“Terms”) have been established by Infinethum (“we”, “our”, or “us”). By using our website, https://infinethum.xyz - including any subdomains, or interacting with associated smart contracts (collectively the “Site”, “Application”, “Services”, or “Smart Contract”) you (“You”, “User”, or “Collector”) expressly agree to comply with all terms and conditions of these Terms.
          </div>
          <div className="LegalSection">
          Please contact us at contact@pxlq.art for any questions or issues.
          </div>
          <div className="LegalSection">
          THESE TERMS OF USE SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE. BY ACCESSING OR USING THE SITE OR APPLICATION, YOU ARE ACCEPTING THESE TERMS OF USE (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS OF USE (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS OF USE, DO NOT ACCESS AND/OR USE THE SITE.THESE TERMS OF USE REQUIRE THE USE OF ARBITRATION (SECTION 17) ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
          </div>
          <div className="LegalSection">
          User Responsibility for Ethereum Wallet / Address
          </div>
          <div className="LegalText">
          In order to use the Site, you may need to connect or sign with an Ethereum wallet (“Wallet”) to the Site. You represent and warrant that you are the exclusive owner and controller of the Wallet. You are responsible for maintaining the confidentiality of any financial information related to your Wallet. ANY USER THAT CONNECTS OR SIGNS TO THE SITE WITH THEIR WALLET EXPRESSLY AGREES TO COMPLY WITH ALL TERMS AND CONDITIONS OF THESE TERMS.
          </div>
          <div className="LegalSection">
          Modification of the Site
          </div>
          <div className="LegalText">
          Infinethum reserves the right, at any time, to modify, suspend, or discontinue the Services and/or Site (in whole or in part) with or without notice to you. You agree that Infinethum will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.
          </div>
          <div className="LegalSection">
          Modification of the Smart Contract
          </div>
          <div className="LegalText">
          Infinethum reserves the right, at any time, to modify, suspend, or discontinue the Smart Contract, and to mandate migration to a new version of the Smart Contract where Infinethum deems it appropriate. Infinethum will give one week’s notice of any proposed migration through Infinethum’s social media channels including the Site.
          </div>
          <div className="LegalSection">
          Indemnification
          </div>
          <div className="LegalText">
          You agree to indemnify and hold Infinethum (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Site, (b) your violation of these Terms of Use, or (c) your violation of applicable laws or regulations. Infinethum reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Infinethum. Infinethum will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
          </div>
          <div className="LegalSection">
          Fees
          </div>
          <div className="LegalText">
          Infinethum reserves the right to charge a fee on secondary sales through the Smart Contract or third-party markets, up to 15% of the purchase amount.
          </div>
          <div className="LegalSection">
          Assumption of Risk
          </div>
          <div className="LegalText">
          You accept and acknowledge that you are solely responsible for determining what, if any, taxes apply to your purchases and sales on the Smart Contract. Infinethum is not responsible for determining the taxes that apply to your Smart Contract transactions. 
          </div>
          <div className="LegalTextBreak">
          By making any purchases, you are purchasing ownership of a token hash that is stored on the Ethereum blockchain and used to generate artwork. The site does not store, send, or receive tokens. The source code used to generate artwork from your token hash is stored on the Ethereum blockchain. Under no circumstances do you have ownership of this source code. Infinethum is not responsible for any bugs, exploits, or hacks. We cannot guarantee that the Site or Smart Contract is free of bugs or errors.
          </div>
          <div className="LegalTextBreak">
          There are risks associated with accepting cryptocurrencies as payment and purchasing blockchain based Tokens, including but not limited to, the risk of losing private keys, theft of cryptocurrency or Tokens due to hackers finding out your private key, lack of a secondary market, significant price volatility, hard forks and disruptions to the Ethereum blockchain. You accept and acknowledge that transfers on the Ethereum blockchain are irreversible and as a result, it is not possible for Infinethum to issue refunds on Token purchases.
          </div>
          <div className="LegalSection">
          Release
          </div>
          <div className="LegalText">
          You hereby release and forever discharge Infinethum (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Application. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
          </div>
          <div className="LegalSection">
          Limitation of Liability
          </div>
          <div className="LegalText">
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL INFINETHUM BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OF USE OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF INFINETHUM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. 
          </div>
          <div className="LegalTextBreak">
          INFINETHUM SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE AS A RESULT OF YOUR FAILURE TO KEEP YOUR WALLET SECURE. INFINETHUM SHALL NOT LIABLE FOR ANY LOSS OR DAMAGE ARISING FROM YOUR FAILURE TO MAINTAIN THE CONFIDENTIALITY OF YOUR FINANCIAL INFORMATION, WHICH INCLUDES YOUR PRIVATE KEYS.
          </div>
          <div className="LegalTextBreak">
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
          </div>
          <div className="LegalTextBreak">
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </div>
        </div>
      </div>
    );
  }
}

export default TermsOfUse;