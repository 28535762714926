import React from 'react';
import { IconCircleNumber1, IconCircleNumber2, IconCircleNumber3, IconCircleNumber4, IconCircleNumber5 } from '@tabler/icons';
import IconHoverable from './IconHoverable.js';

class Pages extends React.Component {
  render() {
    return (
      <div className="Pages">
        <button onClick={()=> this.props.change_page(1)}>
          <IconHoverable 
            Icon={() => <IconCircleNumber1 size="25" color="#1C1B1C" stroke="1"/>}
            IconHover={() => <IconCircleNumber1 size="25" color="#1C1B1C" stroke="2"/>}
          />
        </button>
        <button onClick={()=> this.props.change_page(2)}>
          <IconHoverable 
            Icon={() => <IconCircleNumber2 size="25" color="#1C1B1C" stroke="1"/>}
            IconHover={() => <IconCircleNumber2 size="25" color="#1C1B1C" stroke="2"/>}
          />
        </button>
        <button onClick={()=> this.props.change_page(3)}>
          <IconHoverable 
            Icon={() => <IconCircleNumber3 size="25" color="#1C1B1C" stroke="1"/>}
            IconHover={() => <IconCircleNumber3 size="25" color="#1C1B1C" stroke="2"/>}
          />
        </button>
        <button onClick={()=> this.props.change_page(4)}>
          <IconHoverable 
            Icon={() => <IconCircleNumber4 size="25" color="#1C1B1C" stroke="1"/>}
            IconHover={() => <IconCircleNumber4 size="25" color="#1C1B1C" stroke="2"/>}
          />
        </button>
        <button onClick={()=> this.props.change_page(5)}>
          <IconHoverable 
            Icon={() => <IconCircleNumber5 size="25" color="#1C1B1C" stroke="1"/>}
            IconHover={() => <IconCircleNumber5 size="25" color="#1C1B1C" stroke="2"/>}
          />
        </button>
      </div>
    );
  }
}

export default Pages;