import React from 'react';

class About extends React.Component {
  render() {
    return (
      <div className="Legal">
        <div className="LegalContainer">
          <div className="LegalTitle">
          About
          </div>
          <div className="LegalSection">
          What is it?
          </div>
          <div className="LegalText">
          Infinethum is a curated on-chain generative art platform powered by Art Blocks Engine (AB Engine), originally designed as the proof of concept for AB Engine. The generative code/hash is stored on the Etheruem base layer (L1). This is a place to create and share experimental work under a highly constrained environment.
          </div>
          <div className="LegalSection">
          What are the constraints?
          </div>
          <div className="LegalText">
          Each generative project must output exactly 100 iterations, contain a 1:1 aspect ratio, consist of fewer than 1000 lines, and may only depend on one decentralized dependency. More precisely, scripts must fit within a single transcation, since script concatenation has been repurposed to enable script variants.
          </div>
          <div className="LegalSection">
          What are script variants?
          </div>
          <div className="LegalText">
          Infinethum is different from other AB Engine projects, as scripts can have multiple on-chain variations, allowing unique outputs to be variable while still retaining their most recognizable features. Different variations can be retrieved via the API, which will render a specific project script variation from the chain based on the variant queried. 
          </div>
          <div className="LegalSection">
          What data is available via the API?
          </div>
          <div className="LegalText">api.infinethum.xyz/project/{'{project_id}'}</div>
          <div className="LegalText">api.infinethum.xyz/code/{'{project_id}'}/{'{variant_id}'}</div>
          <div className="LegalText">api.infinethum.xyz/token/{'{token_id}'}</div>
          <div className="LegalText">api.infinethum.xyz/generator/{'{token_id}'}</div>
          <div className="LegalText">api.infinethum.xyz/variant/{'{token_id}'}/{'{variant_id}'}</div>
          <div className="LegalSection">
          What exactly is AB Engine?
          </div>
          <div className="LegalText">
          Platforms powered by AB Engine enables artists to launch their own Art Blocks contracts and make use of the powerful generative art infrastructure created by Art Blocks.
          </div>
        </div>
      </div>
    );
  }
}

export default About;
