import React from 'react';

class Number extends React.Component {
  render() {
    const number = this.props.number;
    return (
      <div className="Number">
        <button className="NumberButton" onClick={()=> this.props.enter_fullscreen(number)}>
        {number}
        </button>
      </div>
    );
  }
}

class Numbers extends React.Component {
  render() {
    let v = Array(100).fill().map((e, i) => i);
    const numbers = v.map((x) => <Number number={x} enter_fullscreen={this.props.enter_fullscreen}/>);
    return (
      <div>{numbers}</div>
    );
  }
}

export default Numbers;