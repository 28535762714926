import React from 'react';
import IconHoverable from './IconHoverable.js';
import { IconBox, IconCurrencyEthereum, IconMaximize } from '@tabler/icons';

class Artwork extends React.Component {
  render() {
    const number = this.props.number;
    return (
      <div className="Artwork">
        <img className="ArtworkImage" src={"https://d2hjejb7ft1tqy.cloudfront.net/"+number+".png"} width="100%" height="100%" alt={number}/>
        <div className="ArtworkInfo">
          <div className="ArtworkNumber">
           <span>No.{number}</span>
          </div>
          <div className="ArtworkLinks">
            <a href="/">
              <IconHoverable 
                Icon={() => <IconBox size="21" color="#FFFFEE" stroke="1"/>}
                IconHover={() => <IconBox size="21" color="#E0FF0E" stroke="2"/>}
              />
            </a>
            <a href={"https://etherscan.io/nft/0xc375f1056f30f26a6915f67f0a19b9476f8937dc/"+number} target="_blank" rel="noreferrer">
              <IconHoverable 
                Icon={() => <IconCurrencyEthereum size="21" color="#FFFFEE" stroke="1"/>}
                IconHover={() => <IconCurrencyEthereum size="21" color="#E0FF0E" stroke="2"/>}
              />
            </a>
            <button onClick={()=> this.props.enter_fullscreen(number)}>
              <IconHoverable 
                Icon={() => <IconMaximize size="21" color="#FFFFEE" stroke="1"/>}
                IconHover={() => <IconMaximize size="21" color="#E0FF0E" stroke="2"/>}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class Artworks extends React.Component {
  render() {
    let v = Array(20).fill().map((e, i) => i+(this.props.page-1)*20);
    const artwork = v.map((x) => <Artwork number={x} enter_fullscreen={this.props.enter_fullscreen}/>);
    return (
      <div className="Artworks">{artwork}</div>
    );
  }
}

export default Artworks;