import React from 'react';
import Artworks from './Artworks.js';
import FullScreen from './FullScreen.js';
import Numbers from './Numbers.js';
import Pages from './Pages.js';

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      page : 1,
      selected : false,
      fullscreen : false
    };

    this.change_page = this.change_page.bind(this);
    this.enter_fullscreen = this.enter_fullscreen.bind(this);
    this.exit_fullscreen = this.exit_fullscreen.bind(this);
  }

  change_page(page) {
    this.setState({
      page: page
    });
  }

  enter_fullscreen(selected) {
    this.setState({
      selected: selected,
      fullscreen: true
    });
  }

  exit_fullscreen() {
    this.setState({
      fullscreen: false
    });
  }

  render() {
    return (
      <div className="Gallery">
        <div className="Numbers">
          <Numbers enter_fullscreen={this.enter_fullscreen}/>
        </div>
        <div className="ProjectInfo">
          <div className="Project">
            <span>Coalescent [0]</span>
          </div>
          <div className="Artist">
            <span>by p𝐗𝓵𝔮 ▚▞▚▗▗▛▜▗▞▘▚▘▜▞▘</span>
            <p>/100</p>
            <p>
              <b>Infinethum is a generative art project</b> for experimental small batch work under a 
              highly constrained environment, <b>powered by ArtBlocks</b>.
            </p>
            <p>
              <b>Coalescent</b> explores complex 
              patterns emerging from simple shapes and rules. Thirty six initial shapes defined 
              within a coordinate system give rise to over ten thousand unique possible outcomes 
              as they coalesce with each other, giving rise to unexpected patterns.
            </p>

          </div>
          <Pages change_page={this.change_page}/>
        </div>
        {
          this.state.fullscreen ? <FullScreen exit_fullscreen={this.exit_fullscreen} number={this.state.selected}/> : <Artworks page={this.state.page} enter_fullscreen={this.enter_fullscreen}/>
        }
      </div>
    );
  }
}

export default Gallery;