import React from 'react';

class IconHoverable extends React.Component {
  constructor() {
    super();
    this.state = { 
      hovered : false
    };
  }
  render () {
    const Icon = this.props.Icon
    const IconHover = this.props.IconHover
    return (
      <div 
        className="IconHoverable" 
        onMouseEnter={()=> this.setState({hovered : true})} 
        onMouseLeave={()=> this.setState({hovered : false})}
      >
        {
          this.state.hovered ? <IconHover/> : <Icon/>
        }
      </div>
    );
  }
}

export default IconHoverable;