import React from 'react';
import { IconArrowsMinimize, IconBoxMultiple0, IconBoxMultiple1, IconBoxMultiple2 } from '@tabler/icons';
import IconHoverable from './IconHoverable.js';

class FullScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      variant : 2
    };

    this.change_variant = this.change_variant.bind(this)
  }

  change_variant(variant) {
    this.setState({
      variant: variant
    })
  }

  render() {
    const number = this.props.number
    const variant = this.state.variant
    const title = "No."+number+".v"+variant
    const src = "https://api.infinethum.xyz/variant/"+number+"/"+variant
    return (
      <div className="FullScreen">
        <div className="FullScreenFrame">
          <div className="FullScreenHeader">
            <div className="FullScreenTitle">
              <span>{title}</span>
            </div>
            <div className="FullScreenExit">
              <button onClick={() => this.props.exit_fullscreen()}>
                <IconHoverable 
                  Icon={() => <IconArrowsMinimize size="24" color="#FFFFEE" stroke="1"/>}
                  IconHover={() => <IconArrowsMinimize size="24" color="#E0FF0E" stroke="2"/>}
                />
              </button>
            </div>
          </div>
          <iframe
            title={title}
            width="600px"
            height="600px"
            frameBorder="0"
            src={src}
          />
          <div className="FullScreenFooter">
            <div className="FullScreenVariants">
              <button onClick={()=> this.change_variant(0)}>
                <IconHoverable
                  Icon={() => <IconBoxMultiple0 size="24" color="#FFFFEE" stroke="1"/>}
                  IconHover={() => <IconBoxMultiple0 size="24" color="#E0FF0E" stroke="2"/>}
                />
              </button>
              <button onClick={()=> this.change_variant(1)}>
                <IconHoverable 
                  Icon={() => <IconBoxMultiple1 size="24" color="#FFFFEE" stroke="1"/>}
                  IconHover={() => <IconBoxMultiple1 size="24" color="#E0FF0E" stroke="2"/>}
                />
              </button>
              <button onClick={()=> this.change_variant(2)}>
                <IconHoverable 
                  Icon={() => <IconBoxMultiple2 size="24" color="#FFFFEE" stroke="1"/>}
                  IconHover={() => <IconBoxMultiple2 size="24" color="#E0FF0E" stroke="2"/>}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullScreen;